/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "remix",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#remix",
    "aria-label": "remix permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Remix"), "\n", React.createElement(_components.p, null, "Configure your ", React.createElement(_components.a, {
    href: "https://remix.run/"
  }, "Remix"), " project to import SVG as React components in your application."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "install",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#install",
    "aria-label": "install permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Install"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm install --save-dev @svgr/cli @svgr/plugin-svgo @svgr/plugin-jsx @svgr/plugin-prettier npm-watch npm-run-all\n")), "\n", React.createElement(_components.h2, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Usage"), "\n", React.createElement(_components.p, null, "Using SVGR into Remix is possible by configuring ", React.createElement(_components.code, null, "package.json"), " scripts."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "package.json")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  //...\n  \"scripts\": {\n    //...\n\n    // task to convert icons to components\n    // you may change the input and output directories\n    \"icons\": \"npx @svgr/cli --out-dir app/components/icons -- app/icons\",\n\n    // watch task\n    \"icons:watch\": \"npm-watch icons\",\n\n    // compile once and start watching for changes\n    \"dev:svg\": \"run-s icons icons:watch\",\n\n    // remix dev\n    \"dev:remix\": \"remix dev\",\n\n    // run all dev: scripts including `dev:svg`\n    \"dev\": \"run-p dev:*\"\n  },\n  // npm-watch configuration\n  \"watch\": {\n    \"icons\": {\n      \"patterns\": [\n        \"icons\"\n      ],\n      \"extensions\": \"svg\",\n      \"quiet\": false\n    }\n  },\n  //...\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "svgr.config.js")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "module.exports = {\n  plugins: ['@svgr/plugin-svgo', '@svgr/plugin-jsx', '@svgr/plugin-prettier'],\n  typescript: true,\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "svgo.config.js")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "module.exports = {\n  plugins: [\n    {\n      name: 'preset-default',\n      params: {\n        overrides: {\n          removeViewBox: false,\n        },\n      },\n    },\n  ],\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Your code")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import Star from '~/icons/star.svg'\n\nconst Example = () => (\n  <div>\n    <Star />\n  </div>\n)\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
